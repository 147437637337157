import Accordion from '../components/Accordion';

function AccordionPage() {
  const items = [
    {
      id: 'qasdj123lk2',
      label: 'TYPEMUSE Architecture: Frontend Engineering',
      content:
        'In the frontend development of the TYPEMUSE system, Im designing and engineering same infrastructure used at large tech companies to handle millions of users seamlessly. This includes utilizing mix of NEXT.js for optimized server-side rendering (SSR) + SSR in Pure React (No Framework like NEXT) and TypeScript for robust type-checking and enhanced development workflows. For state management at an enterprise level, I am integrating Redux and Redux Toolkit Query (RTK Query), ensuring efficient data flow and scalability across the application. Embracing a microfrontend architecture, I will leverage React primarily, complemented by Angular in select modules, to achieve modular development and independent deployment of frontend components. Webpack and Webpack Module Federation will facilitate module isolation and dynamic loading, enhancing performance and maintainability. Additionally, I will utilize Tailwind CSS for streamlined and responsive styling. For static file hosting, Amazon S3 will ensure reliable storage, while Amazon CloudFront will deliver content efficiently through typemuse.com, ensuring a smooth and responsive user experience. But that AWS S3 Service is only for static sites. The large portion of the dynamic web 2.0 frontend system which contains 95% of the large Webapp including tens or dozens of Server Side Rendered NEXT.js applications and few Angular applications, those all will be running inside of multiple Pods on Kubernetes Cluster and the network request i.e traffice from the outside world will be served through Ingress NGINX Load Balancer for distributing traffic across the running Pods on several regions around the world!',
    },
    {
      id: '89jek2jksok',
      label: 'TYPEMUSE Architecture: Backend + DevOps Engineering',
      content: `
    In designing the backend architecture of the TYPEMUSE system, I am adopting a robust engineering strategy capable of scaling to millions of users. The backend will be implemented using a microservices architecture, providing scalability and facilitating future expansion of engineering teams. For the API layer, I will utilize both Express and largely NestJS, leveraging NestJS for its framework capabilities and robust ecosystem. GraphQL will be integrated into specific services, enhancing the efficiency and flexibility of data querying and manipulation. Adopting the Database Per Service pattern, I will heavily utilize PostgreSQL and, in certain instances, MongoDB, ensuring optimized data management tailored to each microservice. Asynchronous communication across microservices will be facilitated by event buses such as Apache Kafka, RabbitMQ, or NATS Streaming Server, enhancing system responsiveness and reliability. Over deep architectural decision, I've come to the conclusion to go with Apache KAFKA for Asynchronous communication as Event Bus System. Im adopting JIRA for project management to implement Agile Software Development Methodology with perhaps the Scrum framework. Continuous integration and delivery (CI/CD) pipelines will be managed using GitHub Actions or Jenkins, ensuring efficient deployment and maintenance processes. Containerization with Docker and orchestration with Kubernetes will streamline communication and scalability between microservices, reflecting the distributed system designs akin to large tech companies in Silicon Valley. Redis will be employed for caching and enhancing performance within the TYPEMUSE backend infrastructure.

    I am also going to utilize WebSockets like Socket.io to establish multiplayer connections and real-time messaging systems, cached with Redis for a high-end infrastructure. Additionally, I will implement either NGINX or Amazon's AWS Elastic Load Balancing (ELB) along with Kubernetes and Docker to ensure efficient load distribution and management of containerized applications.

    Furthermore, I plan to use multiple programming languages and frameworks for backend systems. Some services will be written in Golang, Elixir, Java, Scala (for processing large scale of data) and perhaps Erlang to leverage their respective strengths. It will be Polygot Architecture so for Cross Language Communication, I'll be using either Protobuf or gRPC. Most likely I'll be adopting gRPC since it uses HTTP/2 protocol enhancing performance. I was inspired by the big companies like Google, Meta, Microsoft, Uber, Airbnb and Twitter all employing very large Monorepo Architecture. So for large scale Monorepo, I'll be using either Bazel by Google OR NX for Build System. I will also utilize various Amazon Web Services (AWS) like EC2, Elastic Beanstalk, DynamoDB and perhaps Lambda (λ). So thrilling AWS Compute Services to spin Pods running Containers on EC2 instances provisioning Kubernetes (K8s) Cluster on Either AWS Control Plane managed EKS OR perhaps I will manage Control Plane on my own running K8s on EC2 Instances OR even cool, spinning Kubernetes Engine on Google Cloud Platform (GCP) to ensure scalability, reliability, and high performance. 
  `,
    },
    {
      id: 'zs72j202jl',
      label: 'Startup: Launch Date',
      content:
        'I am pleased to announce that the anticipated launch for TYPEMUSE is scheduled for the fall or summer of 2025. This represents a significant achievement in my development process, and I am dedicated to ensuring a successful and timely release of the product. I am working diligently to meet all objectives and deliver a high-quality solution that meets the needs and expectations of our users. As I progress towards the launch date, I will continue to provide updates and strive to exceed my targets for a successful introduction of TYPEMUSE to the world.',
    },
    {
      id: 'q3oms92jl278',
      label: 'Software Engineer: Man behind TYPEMUSE?',
      content: `
        Hey! I'm Colson, and I am incredibly passionate about building large-scale web applications, tackling complex engineering challenges, and creating something truly special.  My love for microfrontends and microservices is driven by a desire to push the boundaries of what technology can achieve, much like the architecture of big companies like FAANG companies or Fortune 500 companies with multiple engineering teams managing multiple applications. TYPEMUSE, a subsidiary of parent company Syrosin Inc., is my vision to transform the typing experience into something fun, engaging, and incredibly addictive. It's not just about improving your typing speed, but about creating a multiplayer experience that brings joy and productivity together. I'm targeting students from high school to university, graduates, gamers, influencers, engineers, professionals working 9to5 jobs, and anyone who spends a significant amount of time on their computers. Imagine a platform where typing is not just a task, but a thrilling adventure with levels, XP, and so much more. I'm dedicated to making TYPEMUSE a robust and scalable platform that can handle millions of users, and I'm working tirelessly every day and night to bring this vision to life. Join me on this exciting journey, and let's make typing fun and productive together. Find me on Discord @ (.cols) :) I also want to give a shoutout to my brothers and business partners Samridda, whose Discord is @ (zephyr8848), and Shishir, whose Discord is @ (._spark). And also, I want to mention her, the girl I love who is my biggest inspiration in life. She is truly beautiful and gorgeous. I love her very much.
        
      `,
    },
  ];

  return <Accordion items={items} />;
}

export default AccordionPage;
